<template>
  <div>
    <Navbar page="Direitos dos titulares" link="/requisicao/" nameLink="Gestão de Requisições" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">

      <h1 class="text-3xl mb-5">Direitos dos titulares</h1>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Nome
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id">

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.direito ? item.direito : ''}}
                        </div>
                      </div>
                    </td>
                  
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      total: 0,
    }
  },
  methods: {
    async start() {
      const listReq = await this.$http.post(`/v1/direitos/list`, { all: true });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },
  },

  async beforeMount() {
    this.start();
  },
}
</script>